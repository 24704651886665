import { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import getConfig from "next/config";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { EventCategory, HitType, useFsFlag } from "@flagship.io/react-sdk";
import useScreenerStatus from "@ScreenerSetup/hooks/useScreenerStatus";
import { isEmpty } from "lodash-es";
import { destroyCookie, parseCookies } from "nookies";
import useSWR from "swr";
import { breakpoints, Button } from "@boxt/design-system";

import type { Products } from "@Collections/products";
import { API_URLS, BOILER_SUBSCRIPTION_URLS, SCREENING_URLS } from "@Collections/routes";
import type { ButterButtonCollectionItem, ButtonSkinItem } from "@Collections/types/butter/collectionItem";
import { Question } from "@StateMachines/Screener";
import { fetcher } from "@DataAccess/local";
import { sendToCs } from "@Lib/contentsquare";
import { actions, category, labels, ReactGAEvent, screenerCategory } from "@Lib/googleAnalytics";
import LifePopup from "@Components/pages/boilers/installation/LifePopup";
import { Postcode } from "@Components/screener";
import validateRegion, { validateAddress } from "@Components/screener/Postcode/dataAccess";
import useBoxtFlagship from "@Hooks/useBoxtFlagship";
import useMedia from "@Hooks/useMedia";
import { initScreenerWithPostcode } from "@Hooks/useScreener";
import { SCREENER_COOKIE_KEY } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import type { ButterColorFields } from "src/types/butterColors";

import FinishQuote, { getButtonSize } from "./components/FinishQuote/FinishQuote";
import { Container } from "./styles";

const { publicRuntimeConfig } = getConfig();

export type Props = {
  screenerVariant?: ScreenerVariant;
  startQuoteButtonText?: string;
  finishQuoteButtonText?: string;
  finishQuoteButtonTheme?: ButterButtonCollectionItem;
  finishQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonTheme?: ButterButtonCollectionItem;
  typeformScreenerUrl?: string;
  postcodeTooltipTextTheme?: ButterColorFields;
  postcodeToolTipBackgroundColour?: string;
  postcodeLabel?: string;
  postcodePlaceholderMobile?: string;
  postcodePlaceholderDesktop?: string;
  postcodeButtonText?: string;
  postcodeButtonColour?: string;
  productType?: Products;
  mainStartQuoteButtonSkin?: ButtonSkinItem;
  mainStartQuoteButtonText?: string;
  mainStartQuoteButtonTheme?: ButterButtonCollectionItem;
};

const handleAnalyticEvent = (action: string) => {
  ReactGAEvent({
    action,
    category: category.landing,
    label: labels.hero,
  });
};

type ScreenerState = { isScreenerComplete: boolean; isScreenerStarted: boolean };

const ScreenerPostcodeEntry = ({
  finishQuoteButtonSkin,
  finishQuoteButtonText,
  finishQuoteButtonTheme,
  screenerVariant,
  startQuoteButtonSkin,
  startQuoteButtonText,
  startQuoteButtonTheme,
  postcodeTooltipTextTheme,
  postcodeToolTipBackgroundColour,
  postcodeLabel,
  postcodePlaceholderMobile,
  postcodePlaceholderDesktop,
  postcodeButtonText,
  postcodeButtonColour,
  typeformScreenerUrl,
  productType,
  mainStartQuoteButtonSkin,
  mainStartQuoteButtonText,
  mainStartQuoteButtonTheme,
}: Props) => {
  const [isLoadingScreener, setIsLoadingScreener] = useState(false);
  const [isMovingToScreenerPage, setIsMovingToScreenerPage] = useState(false);
  const postcodeInput = useRef<HTMLInputElement | null>(null);
  const isLifeModalFlagEnabled = useFsFlag("nextjs-blp-life-modal", false).getValue();

  const { push, query } = useRouter();
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);
  const { t } = useTranslation("common");

  const screenerState = useScreenerStatus([{ variant: screenerVariant as ScreenerVariant, version: "base" }])[
    screenerVariant as ScreenerVariant
  ];

  const {
    isScreenerStarted: isReduxScreenerStarted,
    isSupported: isScreenerSupportedByRedux,
    isScreenerComplete: isReduxScreenerCompleted,
  } = screenerState || {};
  // Boiler screener will have postcode at the end of the screener journey

  const isScreenerWithPostcodeAtTheEnd = [
    ScreenerVariant.BoilerInstallation,
    ScreenerVariant.BoilerSubscription,
    ScreenerVariant.BatteryOnly,
    ScreenerVariant.Solar,
  ].includes(screenerVariant as ScreenerVariant);

  const { data, error, mutate, isValidating } = useSWR<ScreenerState>(
    `${API_URLS.SCREENING_STATUS}?screenerVariant=${screenerVariant}`,
    (url) => fetcher(url),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  );

  const [isLifeModalOpen, setIsLifeModalOpen] = useState(false);
  const { fireFlagshipEvent } = useBoxtFlagship();

  const skeletonHeight = isMobile ? 60 : 92;

  const isLoading = (!data && !error) || isValidating;

  const isEdfUrl = query?.utm_source?.includes("edf");

  const handleBoilerInstallationStart = () => {
    if (!isEdfUrl) {
      sendToCs("AB_ABT_FS_nextjs-blp-life-modal", isLifeModalFlagEnabled ? "New variation" : "Original");
      if (isLifeModalFlagEnabled) {
        return setIsLifeModalOpen(true);
      }
    }
    goToScreener();
  };

  const handleOnStartQuote = async () => {
    if (isScreenerWithPostcodeAtTheEnd) {
      destroyCookie(null, `${SCREENER_COOKIE_KEY}:${screenerVariant}`, { path: "/" });
      setIsMovingToScreenerPage(true);
      if (screenerVariant === ScreenerVariant.BoilerInstallation) return handleBoilerInstallationStart();
      return goToScreener();
    }
    await mutate(
      (prevData: ScreenerState) => ({
        ...prevData,
        isScreenerStarted: false,
      }),
      { revalidate: false },
    );
    postcodeInput.current?.focus();
  };

  const handleClickForFlagship = (name: string) =>
    fireFlagshipEvent({
      action: name,
      category: EventCategory.ACTION_TRACKING,
      type: HitType.EVENT,
    });

  const handleOnFinishQuote = () => {
    setIsLoadingScreener(true);
    const isComplete = isScreenerSupportedByRedux ? isReduxScreenerCompleted : data?.isScreenerComplete;
    if (isComplete) {
      const cookies = parseCookies();
      const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${screenerVariant}`];
      push({
        pathname: SCREENING_URLS[screenerVariant as ScreenerVariant].complete.replace("[screeningId]", screeningId),
        query,
      });
    } else {
      goToScreener();
    }
  };

  const startScreener = async (screenerPostcode: string) => {
    await initScreenerWithPostcode(screenerVariant as ScreenerVariant, screenerPostcode);
    goToScreener();
  };

  const handleOnSubmit = async (values: { postcode: string }) => {
    handleAnalyticEvent(actions.postcodeGetStarted);
    ReactGAEvent({
      action: actions.postcode,
      category: screenerCategory?.[screenerVariant as ScreenerVariant],
      label: values.postcode,
    });

    if (typeformScreenerUrl) {
      push({ pathname: typeformScreenerUrl });
    } else {
      const postcode = values[Question.Postcode];

      if (publicRuntimeConfig.VALIDATE_POSTCODE === undefined || publicRuntimeConfig.VALIDATE_POSTCODE === "true") {
        await validateAddress(postcode);
      }
      await validateRegion(postcode, productType);
      await startScreener(postcode);
    }
  };

  const handleOnClickRental = () => {
    setIsLoadingScreener(true);
    handleClickForFlagship("Modal life cta click");
    push({
      pathname: BOILER_SUBSCRIPTION_URLS.LANDING,
      query: {
        ...query,
      },
    });
  };

  const handleOnClickPurchase = () => {
    setIsLoadingScreener(true);
    if (isLifeModalFlagEnabled) {
      handleClickForFlagship("Modal install cta click");
    }
    goToScreener();
  };

  const goToScreener = () => {
    delete query.path;
    push({ pathname: SCREENING_URLS[screenerVariant as ScreenerVariant].start, query });
  };

  const isScreenerHasStarted = isScreenerSupportedByRedux ? isReduxScreenerStarted : data?.isScreenerStarted;

  const getStartingComponent = () => {
    if (isScreenerWithPostcodeAtTheEnd) {
      return (
        <Button
          boxtTheme={isEmpty(mainStartQuoteButtonTheme) ? "jade" : mainStartQuoteButtonTheme?.theme}
          data-testid="start-quote-button"
          isFullWidth={isMobile}
          onClick={handleOnStartQuote}
          size={getButtonSize(isDesktop, isMobile)}
          skin={isEmpty(mainStartQuoteButtonSkin) ? "primary" : mainStartQuoteButtonSkin?.name}
        >
          {mainStartQuoteButtonText ? mainStartQuoteButtonText : t("postcode.submit")}
        </Button>
      );
    } else {
      return (
        <Postcode
          buttonColor={postcodeButtonColour}
          label={postcodeLabel}
          onFocus={() => handleAnalyticEvent(actions.postcodeFocus)}
          onSubmit={handleOnSubmit}
          placeholder={{
            md: postcodePlaceholderDesktop,
            sm: postcodePlaceholderMobile,
          }}
          productType={productType}
          ref={postcodeInput}
          submitText={postcodeButtonText}
          tooltipBgColor={postcodeToolTipBackgroundColour}
          tooltipTextTheme={postcodeTooltipTextTheme?.name}
        />
      );
    }
  };
  return (
    <>
      <Container>
        {isLoading || isMovingToScreenerPage ? (
          <Skeleton containerTestId="skeleton-loader" height={skeletonHeight} />
        ) : (
          <>
            {isScreenerHasStarted ? (
              <FinishQuote
                finishQuoteButtonSkin={finishQuoteButtonSkin}
                finishQuoteButtonText={finishQuoteButtonText}
                finishQuoteButtonTheme={finishQuoteButtonTheme}
                isLoading={isLoadingScreener}
                onFinishQuote={handleOnFinishQuote}
                onStartQuote={handleOnStartQuote}
                startQuoteButtonSkin={startQuoteButtonSkin}
                startQuoteButtonText={startQuoteButtonText}
                startQuoteButtonTheme={startQuoteButtonTheme}
              />
            ) : (
              getStartingComponent()
            )}
          </>
        )}
      </Container>
      {isLifeModalOpen && (
        <LifePopup
          isLoadingScreener={isLoadingScreener}
          onClickPurchase={handleOnClickPurchase}
          onClickRental={handleOnClickRental}
          onDismiss={() => setIsLifeModalOpen(false)}
        />
      )}
    </>
  );
};

export default ScreenerPostcodeEntry;
