import type { screenerValuesType } from "@ReduxStore/features/screenerSlice";

import { Products } from "@Collections/products";
import type { StyleConfig } from "@Components/Trans/Trans";
import type { ScreenerVariant } from "@Pages/api/_next/screeners/types";
export const SCREENER_COOKIE_KEY = "boxt_screener_key";

type AnswerType = {
  value: AnswersType | number;
  meta?: Record<string, string | boolean | Record<string, string> | undefined | number>;
};

type AnswerOptions = Record<string, boolean>;

type ModalNextQuestion = {
  modalName: ScreenerModalType;
  answerValue: AnswersType;
};

type AnswersProps = {
  state: screenerValuesType;
  options?: AnswerOptions;
};

type Next =
  | string
  | null
  | {
      path: string;
    }
  | ModalNextQuestion;

type Versions = "base";

type NextProp = {
  value: string;
  state: screenerValuesType;
  options: AnswerOptions;
};

type ValidationType = Record<string, number>;

export const TimeSignature = {
  Is: "is",
  Will: "will",
} as const;

type TimeSignatureType = (typeof TimeSignature)[keyof typeof TimeSignature];

type ScreenerOptions = {
  answerOptions?: AnswerOptions;
  version: Versions;
  variant: ScreenerVariant;
};

type QuestionMeta = {
  assetCollectionName: string | null;
  template: string | null;
  defaultVisibleItemCount: number | null;
  extraInfoComponent: "bottom-sheet" | "side-drawer";
  questionSuffixKey?: string;
  localeKey?: string;
  hasAdditionalInfo?: boolean;
  hasHelpText?: boolean;
  validations?: ValidationType[];
  hasTimeSignature?: boolean;
  styleConfig: StyleConfig;
};

type QuestionProps = {
  name?: QuestionsType;
  next?: Next;
  answers?: AnswerType[];
  answerOptions?: Record<string, boolean>;
  timeSignature?: TimeSignatureType;
};

export const Template = {
  ExpandableList: "expandable_list",
  FilterableList: "filterable_list",
  FreeText: "freetext",
  Info: "info",
  MapBackground: "map_background",
  Redirect: "redirect",
  UnitOfElectricity: "unit_of_electricity",
} as const;

type TemplateType = (typeof Template)[keyof typeof Template];

export const ScreenerModal = { Info: "info" } as const;
type ScreenerModalType = (typeof ScreenerModal)[keyof typeof ScreenerModal];

export const Answers = {
  AddToSolar: "add_to_solar",
  AiringCupboard: "airing cupboard",
  Average: "average",
  BackBoiler: "back boiler",
  BackOfTheHouse: "back_of_the_house",
  Bathroom: "bathroom",
  BatteryOnly: "battery-only",
  Bedroom: "bedroom",
  Bungalow: "bungalow",
  Combi: "combi",
  ConversionToAiringCupboard: "conversion to airing cupboard",
  Cupboard: "cupboard",
  Detached: "detached",
  DoesNotFitWithOurPlans: "Doesn't fit with our plans",
  DontKnow: "dont_know",
  Dormer: "dormer",
  Fast: "fast",
  Flat: "flat",
  FourteenToSixteenRadiators: "14-16 radiators",
  Garage: "garage",
  GarageBattery: "garage_battery",
  Gas: "gas",
  HighestTwoThirds: "highest two-thirds",
  Homeowner: "homeowner",
  IDontKnow: "I don't know",
  Inside: "inside",
  Kitchen: "kitchen",
  Landlord: "landlord",
  LessThanTwoMetres: "less than two metres",
  LoftOrAttic: "Loft or attic",
  LowestThird: "lowest third",
  Lpg: "lpg",
  MiddleOfTheHouse: "middle of the house",
  MoreThanTwoMetres: "more than two metres",
  MoveSomewhereElse: "move somewhere else",
  MoveToAiringCupboard: "move to airing cupboard",
  No: "no",
  NotWorking: "Not working",
  Oil: "oil",
  OldInefficient: "Old & inefficient",
  OnAnOutsideWall: "on an outside wall",
  OneFloor: "one floor",
  OneToFive: "1 to 5",
  OneToTwoMeters: "1-2 metres",
  Other: "other",
  Outside: "outside",
  OverSeventeenRadiators: "Over 17 radiators",
  Pitched: "pitched",
  Renting: "renting",
  Roof: "roof",
  Round: "round",
  SameRoom: "same room",
  SemiDetached: "semi-detached",
  SemiDetachedSolar: "semi_detached",
  SideOfTheGarage: "side_of_the_garage",
  SideOfTheHouse: "side_of_the_house",
  SixToNineRadiators: "6-9 radiators",
  SixToTen: "6 to 10",
  Sloped: "sloped",
  Slow: "slow",
  SolarPanelsAndBattery: "solar-panels-and-battery",
  SomewhereElse: "somewhere else",
  SomewhereElseSolar: "somewhere_else",
  Square: "square",
  StandaloneBattery: "standalone_battery",
  Standard: "standard",
  System: "system",
  TenPlus: "10 plus",
  TenToThirteenRadiators: "10-13 radiators",
  TenToTwenty: "10 to 20",
  Terrace: "terrace",
  ThreePlus: "3+",
  ThreePlusMeters: "3+ metres",
  TwentyFivePlus: "25 plus",
  TwentyToTwentyFive: "20 to 25",
  TwoFloors: "two floors",
  TwoPlus: "2+",
  TwoToThreeMeters: "2-3 metres",
  UnderOneMetre: "Under 1 metre",
  UpToOne: "Up to 1",
  UpToTen: "Up to 10",
  Utility: "utility",
  UtilityRoom: "utility room",
  Wall: "wall",
  Yes: "yes",
  ZeroToFiveRadiators: "0-5 radiators",
} as const;

type AnswersType = (typeof Answers)[keyof typeof Answers];

export const Questions = {
  AcFaultCode: "acFaultCode",
  AcGeneralProblemDescription: "acGeneralProblemDescription",
  AnnualElectricityUsage: "annualElectricityUsage",
  BackBoiler: "backBoiler",
  BatteryLocation: "batteryLocation",
  BatteryOnlyOrSolarWithBattery: "batteryOnlyOrSolarWithBattery",
  BatterySpace: "batterySpace",
  BoilerCoAlarmDescription: "boilerCoAlarmDescription",
  BoilerDescription: "boilerDescription",
  BoilerDescriptionBackBoiler: "boilerDescriptionBackBoiler",
  BoilerGasSmellDescription: "boilerGasSmellDescription",
  BoilerGeneralProblemDescription: "boilerGeneralProblemDescription",
  BoilerLocation: "boilerLocation",
  BoilerMake: "boilerMake",
  BoilerType: "boilerType",
  BrokenPlace: "brokenPlace",
  BungalowFloors: "bungalowFloors",
  BuyFromBoxt: "buyFromBoxt",
  COAlarmInfo: "coAlarmInfo",
  ConvertCombi: "convertCombi",
  CostOfUnitOfElectricity: "costOfUnitOfElectricity",
  CurrentBoilerLocation: "currentBoilerLocation",
  CurrentlyHaveHomecover: "currentlyHaveHomecover",
  CustomBoilerLocation: "customBoilerLocation",
  CustomerType: "customerType",
  Distance: "distance",
  ElectricShower: "electricShower",
  EpcReference: "epc",
  EvFaultCode: "evFaultCode",
  EvGeneralProblemDescription: "evGeneralProblemDescription",
  ExtraCharges: "extraCharges",
  FaultCode: "faultCode",
  FlatAboveSecondFloor: "flatAboveSecondFloor",
  FloorArea: "floorArea",
  FlueShape: "flueShape",
  Fuel: "fuel",
  GasLeakInfo: "gasLeakInfo",
  GoogleRef: "googleRef",
  HasShowersOverBath: "hasShowersOverBath",
  HaveMeter: "haveMeter",

  HaveTrvs: "haveTrvs",

  HeatPump: "heatPump",

  HeatingUsage: "heatingUsage",
  HomeLocation: "homeLocation",
  HomeType: "homeType",
  HotWaterUsage: "hotWaterUsage",
  HowCloseFlueIsToGround: "howCloseFlueIsToGround",
  HowCloseToAnotherPropertyIsYourFlue: "howCloseToAnotherPropertyIsYourFlue",
  HowFarIsBoilerFromWall: "howFarIsBoilerFromWall",
  HowLong: "howLong",
  HowOld: "howOld",
  InsideLocation: "insideLocation",
  InstallType: "installType",
  IsFlueAwayFromDoorOrWindow: "isFlueAwayFromDoorOrWindow",
  IsFlueOnSlopedOrFlatRoof: "isFlueOnSlopedOrFlatRoof",
  IsFlueUnderStructure: "isFlueUnderStructure",
  IsIssueSubmitted: "isIssueSubmitted",
  NewAirConLocation: "newAirConLocation",
  NewBoilerLocation: "newBoilerLocation",
  NewBoilerLocationSwap: "newBoilerLocationSwap",
  NewLocation: "newLocation",
  NightRate: "nightRate",
  NumOfBaths: "numOfBaths",
  NumOfBedrooms: "numOfBedrooms",
  NumOfOccupants: "numOfOccupants",
  NumOfPanels: "numOfPanels",
  NumOfRadiators: "numOfRadiators",
  NumOfSeparateShowers: "numOfSeparateShowers",
  NumOfShowersOverBath: "numOfShowersOverBath",
  OffStreetParking: "offStreetParking",
  OrderDetails: "orderDetails",
  OutsideLocation: "outsideLocation",
  OwnSolarSystem: "ownSolarSystem",
  Postcode: "postcode",
  PowerCuts: "powerCuts",
  PowerShower: "powerShower",
  PressureDropped: "pressureDropped",
  PropertyOwnership: "propertyOwnership",
  PumpSeparated: "pumpSeparated",
  Reference: "reference",
  RoofType: "roofType",
  ShadeOnRoof: "shadeOnRoof",
  SmartMeter: "smartMeter",
  Triage: "triage",
  WallMounted: "wallMounted",
  WaterPressure: "waterPressure",
  WhatIsProblemAc: "whatIsProblemAc",
  WhenDidIssueStart: "whenDidIssueStart",
  WhereBoilerNewLocation: "whereBoilerNewLocation",
  WhereBoilerNewLocationAiringCupboardConversion: "whereBoilerNewLocationAiringCupboardConversion",
  WhereDoesFlueComeOut: "whereDoesFlueComeOut",
  WhereEvInstalled: "whereInstalled",
  WhereInstalled: "whereInstalled",
  WhereIsAiringCupboard: "whereIsAiringCupboard",
  WhereOnRoofIsItPositioned: "whereOnRoofIsItPositioned",
};
type QuestionsType = (typeof Questions)[keyof typeof Questions];

export const DeadEndNods = {
  [Products.Boiler]: {
    Callout: "/boilers/callout",
    CalloutOil: "/boilers/callout/oil",
  },
  [Products.BoilerRental]: {
    Callout: "/boilers/callout",
    CalloutOil: "/boilers/callout/oil",
  },
  [Products.Solar]: {
    UnableToInstallFlat: "/solar/unable-to-install?issue=flat",
    UnableToInstallFlatRoof: "/solar/unable-to-install?issue=flat-roof",
    UnableToInstallRenting: "/solar/unable-to-install?issue=renting",
  },

  [Products.BatteryOnly]: {
    NoSpace: "/home-battery-storage/no-space",
    Solar: "/solar",
  },
};

type DeadEndNodsType = (typeof DeadEndNods)[keyof typeof DeadEndNods];

export type {
  AnswerOptions,
  AnswersProps,
  AnswersType,
  AnswerType,
  DeadEndNodsType,
  ModalNextQuestion,
  Next,
  NextProp,
  QuestionMeta,
  QuestionProps,
  QuestionsType,
  ScreenerModalType,
  ScreenerOptions,
  TemplateType,
  TimeSignatureType,
  ValidationType,
  Versions,
};
